$sidebarWidth: 250px;
$sidebarCollapsedWidth: 64px;

body {
  overflow: hidden;
}
.app-drawer-wrapper {
  display: flex;
  height: 100vh;
  .nav-bar-wrapper {
    min-width: 250px;
    &:has(.shell-menu-inline-collapsed) {
      min-width: 64px;
    }
  }
}

.main-container-wrapper {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  overflow: auto;
}

.main-container {
  height: 100%;
}
.env-badge-label.env-badge-label {
  display: inline-flex;
  margin-left: 10px;
  color: var(--env-badge-text-color);
  background-color: var(--env-badge-development-color);
  &.uat {
    background-color: var(--env-badge-uat-color);
  }
  &.sbx {
    background-color: var(--env-badge-sbx-color);
  }
  &.dev {
    background-color: var(--env-badge-dev-color);
  }
  &.sds {
    background-color: var(--env-badge-sds-color);
  }
}

.company-logo-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 4px 0;
  .company-logo {
    width: 32px;
  }
  .company-name {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    color: var(--header-label-color);
    margin-left: 15px;
  }
}

.admin-sidebar-menu-wrapper.admin-sidebar-menu-wrapper {
  max-width: "250px";
  padding-bottom: "10px";

  .shell-menu-sub,
  .shell-menu-inline {
    .shell-menu-item-group {
      .shell-menu-item-group-title {
        padding-left: 20px;
        .shell-menu-label {
          color: var(--submenu-menu-group-title-color);
        }
      }
      .shell-menu-item {
        padding: 5px 16px;
        .shell-menu-label {
          padding: 4px 8px;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

.cards-wrapper {
  .cards-inner-flex {
    flex-wrap: wrap;
  }
  .card-inner-wrap-link {
    margin: 0 20px 20px 0;
  }
  .card-body {
    min-height: 140px;
    min-width: 310px;
    &:hover {
      box-shadow: var(--black-50-A) 0px 6px 24px, var(--black-100-A) 0px 2px 6px;
    }
  }
  .card-icon {
    font-size: 64px;
    align-self: center;
    justify-self: center;
    color: var(--dashboard-card-icon-color);
  }
}

.notification-wrapper {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  align-items: flex-start;
  flex-wrap: wrap-reverse;
}

.notification-title {
  padding-right: 10px;
}
